export const SET_QUIZ = 'SET_QUIZ';
export const RESET = 'RESET';
export const SET_QUESTION = 'SET_QUESTION';
// export const UPDATE_FILES = 'UPDATE_FILES';

export const setFormQuiz = formQuiz => ({
  type: SET_QUIZ,
  payload: formQuiz,
});

export const setQuestion = questions => ({
  type: SET_QUESTION,
  payload: questions,
});

// export const setUpdateFile = files => ({
//   type: UPDATE_FILES,
//   payload: files,
// });

export const setResetForm = () => ({
  type: RESET,
});
