// src/redux/host/host.reducer.js

import { SET_MATERI, RESET, SET_NOTES, UPDATE_FILES } from './materi-module.action';

const initialState = {
  title: null,
  description: '',
  topic: null,
  classes: null,
  students: null,
  files: null,
  // schedule: null,
  links: null,
  // this is for module not tugaku module
  filesModul: [],
};

const materiModuleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MATERI:
      return {
        ...state,
        title: action.payload.title,
        description: action.payload.description,
        topic: action.payload.topic,
        classes: action.payload.classes,
        students: action.payload.students,
        files: action.payload.files,
        // schedule: action.payload.schedule,
        links: action.payload.links,
      };
    case UPDATE_FILES:
      return {
        ...state,
        files: action.payload,
      };
    case SET_NOTES:
      return {
        ...state,
        filesModul: action.payload,
      };
    case RESET:
      console.log('RESET action dispatched');
      return initialState;
    default:
      return state;
  }
};

export default materiModuleReducer;
