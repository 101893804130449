export const SET_MATERI = 'SET_MATERI';
export const RESET = 'RESET';
export const SET_NOTES = 'SET_NOTES';
export const UPDATE_FILES = 'UPDATE_FILES';

export const setFormMateri = formMateri => ({
  type: SET_MATERI,
  payload: formMateri,
});

export const setNotes = notes => ({
  type: SET_NOTES,
  payload: notes,
});

export const setUpdateFile = files => ({
  type: UPDATE_FILES,
  payload: files,
});

export const setResetForm = () => ({
  type: RESET,
});
