// src/redux/host/host.reducer.js

import { SET_QUESTION, RESET, SET_QUIZ, UPDATE_FILES } from './tugasku-quiz.action';

const initialState = {
  title: null,
  description: '',
  topic: null,
  classes: null,
  students: null,
  duration: null,
  question: null,
};

const quizTugaskuReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_QUIZ:
      return {
        ...state,
        title: action.payload.title,
        description: action.payload.description,
        topic: action.payload.topic,
        classes: action.payload.classes,
        students: action.payload.students,
        duration: action.payload.duration,
        question: action.payload.question,
      };
    // case UPDATE_QUESTION:
    //   return {
    //     ...state,
    //     question: action.payload,
    //   };
    case SET_QUESTION:
      return {
        ...state,
        question: action.payload,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default quizTugaskuReducer;
